
import { defineAsyncComponent, ref } from 'vue'
import { createTag, Tag } from 'momai'
import { saveOrUpdate } from '@/api/entity/tag'
import { Notify } from 'vant'
import { goBack } from '@/states/layout'
import { fetchTags } from '@/states/tags'

export default {
  components: {
    TagForm: defineAsyncComponent(() => import('../Form.vue'))
  },
  setup: () => {
    const tag = ref<Tag>(createTag())
    return {
      tag,
      doSubmit: (submit: () => boolean) => {
        if (!submit()) {
          return
        }
        saveOrUpdate(tag.value).then(() => {
          fetchTags()
          Notify({
            type: 'success',
            message: '标签添加成功！'
          })
          goBack()
        })
      }
    }
  }
}
